import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { Button } from '../components/Button';
import { Link } from '../components/Link';
import { EvaluatePageRouteProps } from '../../App';
import { Grid, Row, Col } from '../components/grid';
import { history, StoreType } from '../../store';
import { actions } from '../Evaluate/actions';
import { ExperimentType, experiments } from '../../config';
import style from './ExperimentPage.module.scss';

type ExperimentPageType = typeof actions & StoreType & EvaluatePageRouteProps;

@connect(store => store, actions)
export class ExperimentPage extends React.Component<ExperimentPageType> {
  componentWillMount = () => {
    document.body.className = 'blue';
  }

  goToEvaluation = (experiment: ExperimentType) => {
    history.push({
      pathname: `/evaluate/${experiment.id}`
    });
  }

  goToResults = (experiment: ExperimentType) => {
    history.push({
      pathname: `/results/${experiment.id}`
    });
  }

  getEvaluatedVideosCount = () => {
    let evaluatedVideosCount = 0;
    const experimentId = this.props.match.params.experimentId;
    const result = this.props.evaluate.results[experimentId];
    const experiment = experiments.find(experiment => experiment.id === experimentId);
    if (!experiment) return 0;
    experiment.videos.forEach((video) => {
      if (result && result[video.filename]) {
        evaluatedVideosCount++;
      }
    });
    return evaluatedVideosCount;
  }

  getTotalVideosCount = () => {
    const experimentId = this.props.match.params.experimentId;
    const experiment = experiments.find(experiment => experiment.id === experimentId);
    if (!experiment) return 0;
    return experiment.videos.length;
  }

  getExperiment = () => {
    const experimentId = this.props.match.params.experimentId;
    const experiment = experiments.find(experiment => experiment.id === experimentId);
    return experiment!;
  }

  render() {
    const experimentId = this.props.match.params.experimentId;
    const experiment = experiments.find(experiment => experiment.id === experimentId);
    const experimentName = experiment ? experiment.name : '';
    const progressPercentage = Math.ceil(this.getEvaluatedVideosCount() / this.getTotalVideosCount() * 100);

    return (
      <Grid className={style.container}>
        <Row>
          <Col md={12}>
            <Link to="/" color="white" className={style.navbarBackButton}>Back</Link>
            <h1 className={style.header}>{experimentName}</h1>
            <span className={style.subheader}>Scoring progress ({progressPercentage}%)</span>
            {this.renderProgress(this.getEvaluatedVideosCount(), this.getTotalVideosCount())}
            {this.renderSummary()}
          </Col>
        </Row>
      </Grid>
    );
  }

  renderProgress = (completedCount: number, total: number) => {
    const distPercentage = (Math.round((100 / (total - 1)) * 100) / 100); // 33.33 for 4 videos
    const progressBarWidth = Math.min(completedCount * distPercentage, 100);

    const ticks: any = [];
    for (let i = 0; i < total; i++) {
      const left = `${(i ) * distPercentage}%`;
      if (i < completedCount) {
        // completed
        ticks.push(
          <div key={i} className={classNames([style.point, style.completed])} style={{ left }}>
            <Icon icon="tick" className={style.pointTick} />
          </div>
        );
      } else if (i === completedCount) {
        // current
        ticks.push(
          <div key={i} className={classNames([style.point])} style={{ left }}>
            <span className={style.pointCurrent} />
          </div>
        );
      } else {
        // empty
        ticks.push(
          <div key={i} className={classNames([style.point])} style={{ left }} />
        );
      }
    }

    return (
      <div className={style.progress}>
        <div className={style.bar} style={{ width: `${progressBarWidth}%` }} />
        {ticks}
      </div>
    );
  }

  renderSummary = () => {
    const experiment = this.getExperiment();
    const isDone = this.getEvaluatedVideosCount() === this.getTotalVideosCount();

    if (isDone) {
      return (
        <div className={style.summaryContainer}>
          <h1 className={style.summaryHeader}>Your results are ready</h1>
          <Button color="white" onClick={() => this.goToResults(experiment)}>See the results</Button>
        </div>
      );
    } else {
      return (
        <div className={style.summaryContainer}>
          <Button color="white" onClick={() => this.goToEvaluation(experiment)}>Score next recording</Button>
          <p className={style.summaryLabel}>Order is randomized</p>
        </div>
      );
    }
  }
}

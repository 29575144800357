import React from 'react';
import classNames from 'classnames';
import styles from './Grid.module.scss';

export interface RowProps {
  className?: string;
  children: React.ReactNode;
}

export const Row = (props: RowProps) => {
  const className = classNames([styles.row, props.className]);

  return <div className={className}>{props.children}</div>;
};

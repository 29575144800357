import React from 'react';
import { connect } from 'react-redux';
import { Button } from '../components/Button';
import { EvaluatePageRouteProps } from '../../App';
import { Grid, Row, Col } from '../components/grid';
import { history, StoreType } from '../../store';
import { actions } from '../Evaluate/actions';
import { ExperimentType, experiments } from '../../config';
import style from './ExperimentsPage.module.scss';

import packageJson from '../../../package.json';

// FIXME: move images to config
import fstImage from './fst.png';
import norImage from './nor.png';
import epmImage from './epm.png';
import painImage from './pain.png';

// import missingImage from './missing.png';
const images = {
  fst: fstImage,
  nor: norImage,
  epm: epmImage,
  pain: painImage
};

type ExperimentsPageType = typeof actions & StoreType & EvaluatePageRouteProps;

@connect(store => store, actions)
export class ExperimentsPage extends React.Component<ExperimentsPageType> {
  componentWillMount = () => {
    document.body.className = 'blue';
  }

  goToExperiment = (experiment: ExperimentType) => {
    history.push({
      pathname: `/experiment/${experiment.id}`
    });
  }

  resetResults = () => {
    if (confirm('Are you sure? This will erase all results')) {
      this.props.resetResults();
    }
  }

  render() {
    return (
      <div>
        <Grid className={style.container}>
          <Row>
            <Col md={12}>
              <h1 className={style.header}>Please choose the experiment</h1>
              {this.renderResetButton()}
            </Col>
          </Row>
          <Row>
            {experiments.map(this.renderExperiment)}
          </Row>
        </Grid>
        <div className={style.version}>v{packageJson.version}</div>
      </div>
    );
  }

  renderExperiment = (experiment: ExperimentType) => {
    const columnGridWidth = Math.floor(12 / experiments.length);
    return (
      <Col md={columnGridWidth} key={experiment.id}>
        <div className={style.card}>
          <div className={style.cardHeader}>
            <h2 className={style.h2}>{experiment.name}</h2>
          </div>
          <div className={style.imageContainer}>
            <img src={images[experiment.id]} alt={experiment.id} />
          </div>
          {this.renderButton(experiment)}
        </div>
      </Col>
    );
  }

  renderButton = (experiment: ExperimentType) => {
    return <Button onClick={() => this.goToExperiment(experiment)} className={style.button}>Select</Button>;
  }

  renderResetButton = () => {
    return (
      <Button
        className={style.resetResultsButton}
        onClick={this.resetResults}
        color="white-transparent"
      >
        Reset the results
      </Button>
    );
  }
}

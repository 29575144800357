import React from 'react';
import classNames from 'classnames';
import style from './Button.module.scss';

type ButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  color?: 'blue' | 'white' | 'white-transparent';
};

export const Button = (props: ButtonProps) => {
  const classes = [props.className, style.button, style[props.color || 'blue']];

  return (
    <button
      className={classNames(classes)}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled || false}
    >
      {props.children}
    </button>
  );
};


import * as _ from 'lodash';
import {
  EVALUATE_PLAY,
  EVALUATE_STOP,
  EVALUATE_ON_PLAYBACK_PROGRESS,
  EVALUATE_ON_PLAYBACK_END,
  EVALUATE_SET_EXPERIMENT,
  EVALUATE_SET_ACTIVITY,
  EVALUATE_SAVE_RESULTS,
  EVALUATE_LOAD_RESULTS,
  EVALUATE_RESET_RESULTS,
  RESET_VIDEO
} from './actions';
import { EvaluateActionsType } from './actions.types';
import {
  EvaluateStateType,
  SetExperimentPropsType,
  SetPlaybackPropsType,
  SetActivityPropsType
} from './reducer.types';
import { experiments, ExperimentType } from '../../config';

const initialState: EvaluateStateType = {
  results: {},

  // evaluation
  currentActivityKey: 'idle',
  activities: {},

  // playback
  playing: false,
  ended: false,
  progress: {
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0
  }
};

const setExperimentReducer = ({ action, state }: SetExperimentPropsType) => {
  const currentExperiment = experiments.find(experiment => experiment.id === action.experimentId) as ExperimentType;
  const activityKeys = Object.keys(currentExperiment!.activities);
  const activities = {};
  let currentActivityKey = state.currentActivityKey;

  // set default values for the activities
  activityKeys.forEach((key) => {
    const activity = currentExperiment!.activities[key];
    activities[key] = {
      name: activity.name,
      value: 0,
      count: 0
    };
    if (activity.default) {
      currentActivityKey = key;
    }
  });

  // depending on the progress, set video url
  const notEvaluatedVideos = currentExperiment.videos.filter((video) => {
    return !_.get(state.results, [currentExperiment.id, video.filename], false);
  });
  const currentVideoUrl = notEvaluatedVideos.length ? _.shuffle(notEvaluatedVideos)[0].filename : '';
  return {
    ...state,
    progress: initialState.progress,
    playing: false,
    ended: false,
    currentExperiment,
    currentActivityKey,
    activities,
    currentVideoUrl
  };
};

const setPlaybackProgressReducer = ({ action, state }: SetPlaybackPropsType) => {
  let currentActivityKey = state.currentActivityKey;
  const diff = action.progress.playedSeconds - state.progress.playedSeconds;
  const newState = { ...state };

  newState.activities[currentActivityKey].value += diff;
  newState.progress = action.progress;

  if (state.currentExperiment!.id === 'nor') {
    // NOR has a special end-condition:
    // if mouse explores the either of the objects for longer than 20 seconds
    // we should end an experiment
    // FIXME: this logic should be refactored outside of the reducer
    const newObjectExplorationTime = _.get(state, 'activities.newObject.value', 0);
    const oldObjectExplorationTime = _.get(state, 'activities.oldObject.value', 0);
    if (newObjectExplorationTime + oldObjectExplorationTime > 20) {
      newState.playing = false;
      newState.ended = true;
    }
  }

  return newState;
};

const setActivityReducer = ({ action, state }: SetActivityPropsType) => {
  const newActivityKey = action.activityKey;
  if (state.currentActivityKey !== newActivityKey) {
    state.activities[newActivityKey].count += 1;
  }
  return { ...state, currentActivityKey: newActivityKey };
};

export const evaluateReducer =
  (state: EvaluateStateType = initialState, action: EvaluateActionsType): EvaluateStateType => {
    switch (action.type) {
      case EVALUATE_SET_EXPERIMENT:
        return setExperimentReducer({ action, state });
      case EVALUATE_SET_ACTIVITY:
        return setActivityReducer({ action, state });
      case EVALUATE_ON_PLAYBACK_PROGRESS:
        return setPlaybackProgressReducer({ state, action });
      case EVALUATE_ON_PLAYBACK_END:
        return { ...state, playing: false, ended: true };
      case EVALUATE_PLAY:
        return { ...state, playing: true };
      case EVALUATE_STOP:
        return { ...state, playing: false };
      case EVALUATE_SAVE_RESULTS:
      case EVALUATE_LOAD_RESULTS:
      case EVALUATE_RESET_RESULTS:
        return { ...state, results: action.results, currentVideoUrl: undefined};
      case RESET_VIDEO:
        return { ...state, currentVideoUrl: undefined };
      default:
        return state;
    }
  };

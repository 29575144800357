import React from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Icon, NonIdealState, Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core';
import { Grid, Row, Col } from '../components/grid';
import { Link } from '../components/Link';
import { Button } from '../components/Button';
import { actions } from './actions';
import { StoreType, history } from '../../store';
import { PlaybackProgressType } from './actions.types';
import { EvaluatePageRouteProps } from '../../App';
import { experiments } from '../../config';
import style from './EvaluatePage.module.scss';

const PROGRESS_INTERVAL = 50;

type EvaluatePageProps = typeof actions & StoreType & EvaluatePageRouteProps;

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const paddedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  return `${minutes}:${paddedSeconds}`;
};

@connect(store => store, actions)
@HotkeysTarget
export class EvaluatePage extends React.Component<EvaluatePageProps> {
  componentWillMount() {
    document.body.className = 'white';
    const experimentId = this.props.match.params.experimentId;
    this.props.setActiveExperiment(experimentId);
  }

  componentWillUnmount() {
    this.props.resetVideo();
  }

  getExperiment = () => {
    const experimentId = this.props.match.params.experimentId;
    const experiment = experiments.find(experiment => experiment.id === experimentId);
    // FIXME !
    return experiment!;
  }

  onProgress = (progress: PlaybackProgressType) => {
    this.props.onPlaybackProgress(progress);
  }

  onEnded = () => {
    this.props.onPlaybackEnd();
  }

  togglePlayback = () => {
    const { playing } = this.props.evaluate;
    if (playing) {
      this.props.stop();
    } else if (this.canPlay()) {
      this.props.play();
    }
  }

  setActivity = (activityKey: string) => {
    this.props.setActivity(activityKey);
  }

  goBack = () => history.push({ pathname: '/' });

  save = () => {
    if (this.canSave()) {
      this.props.saveResults(this.props.evaluate);
      const experimentId = this.props.match.params.experimentId;
      history.push({ pathname: `/experiment/${experimentId}` });
    }
  }

  render() {
    return (
      <>
        {this.renderNavbar()}
        <Grid fluid={true} className={style.container}>
          {this.props.evaluate.currentVideoUrl ? this.renderEvaluate() : this.renderNoVideosToEvaluate()}
        </Grid>
      </>
    );
  }

  canSave = () => this.props.evaluate.ended;
  canPlay = () => !this.props.evaluate.ended;

  renderNavbar() {
    const experimentId = this.props.match.params.experimentId;
    const saveClasses = [style.navbarSave];
    if (this.canSave()) {
      saveClasses.push(style.navbarSaveActive);
    }

    return (
      <div className={style.navbar}>
        <Link to={`/experiment/${experimentId}`} color="white" className={style.navbarBackButton}>Back</Link>
        <h1 className={style.navbarHeader}>{this.getExperiment().name}</h1>
        <Button
          className={classNames(saveClasses)}
          onClick={this.save}
          color="white"
          disabled={!this.canSave()}
        >
          Save
        </Button>
      </div>
    );
  }

  renderEvaluate() {
    const { currentActivityKey, playing, activities } = this.props.evaluate;
    const activityKeys = Object.keys(activities);
    const cursorKeys = ['caret-left', 'caret-up', 'caret-right'];

    // Played: {this.props.evaluate.progress.playedSeconds}s

    return (
      <Row>
        <Col md={6}>
          <ReactPlayer
            className={style.videoPlayer}
            url={this.props.evaluate.currentVideoUrl}
            playing={playing}
            muted={true}
            width="100%"
            height="600px"
            onProgress={this.onProgress}
            onEnded={this.onEnded}
            progressInterval={PROGRESS_INTERVAL}
            config={{
              vimeo: {
                playerOptions: {
                  controls: false,
                }
              }
            }}
          />
        </Col>
        <Col md={6}>
          <Grid fluid={true}>
            <Row>
              {activityKeys.map((activityKey, i) => {
                const activity = activities[activityKey];
                const buttonClasses = [style.activityButton];
                const timeClasses = [style.activityTime];
                const labelClasses = [style.activityLabel];
                const columnGridWidth = Math.floor(12 / activityKeys.length);

                if (currentActivityKey === activityKey) {
                  buttonClasses.push(style.activityButtonActive);
                  timeClasses.push(style.activityTimeActive);
                  labelClasses.push(style.activityLabelActive);
                }

                return (
                  <Col sm={columnGridWidth} className={style.resultColumn} key={i}>
                    <div className={classNames(timeClasses)}>
                      {formatTime(Math.round(activity.value))}
                    </div>

                    <Button
                      className={classNames(buttonClasses)}
                      key={activityKey}
                      onClick={() => this.setActivity(activityKey)}
                    >
                      <Icon icon={cursorKeys[i] as any} iconSize={20} />
                    </Button>

                    <div className={classNames(labelClasses)}>
                      {activity.name}
                    </div>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col md={12}>
                <div className={style.togglePlayback}>
                  <Button
                    onClick={this.togglePlayback}
                    className={style.togglePlaybackButton}
                    disabled={!this.canPlay()}
                  >
                    {playing ? 'Pause' : 'Play'}
                  </Button>
                </div>
              </Col>
            </Row>
          </Grid>
        </Col>
      </Row>
    );
  }

  renderNoVideosToEvaluate() {
    return (
      <NonIdealState
        title="No videos to evaluate"
        action={<Button onClick={() => history.push({ pathname: '/' })}>Back</Button>}
      />
    );
  }

  renderHotkeys() {
    const activityKeys = Object.keys(this.props.evaluate.activities);
    const keyboardKeys = ['left', 'up', 'right'];
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          combo="space"
          label="Play / pause"
          onKeyDown={() => this.togglePlayback()}
        />
        {activityKeys.map((activityKey, i) => (
          <Hotkey
            key={`hotkey-${i}`}
            global={true}
            combo={keyboardKeys[i]}
            label={`Set ${activityKey}`}
            onKeyDown={() => this.setActivity(activityKey)}
          />
        ))}
      </Hotkeys>
    );
  }
}

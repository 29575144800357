import * as _ from 'lodash';
import {
  PlaybackProgressType,
  ActivityType,
  OnPlaybackProgressType,
  OnPlaybackEndType,
  PlayType,
  StopType,
  SetActivityType,
  SetExperimentType,
  SaveResultsPropsType,
  SaveResultsType,
  LoadResultsType,
  EvaluateActionsType,
  ResetResultsType
} from './actions.types';
import { EvaluateStateType } from './reducer.types';

// playback actions
export const EVALUATE_ON_PLAYBACK_PROGRESS = 'evaluate.progress';
export const EVALUATE_ON_PLAYBACK_END = 'evaluate.progress.end';
export const EVALUATE_PLAY = 'evaluate.start';
export const EVALUATE_STOP = 'evaluate.stop';

// set active experiment, after choosing it on the main page
export const EVALUATE_SET_EXPERIMENT = 'evaluate.experiment.set';
// during the evaluation, choose the selected activity
export const EVALUATE_SET_ACTIVITY = 'evaluate.activity.set';
// save results to local storage
export const EVALUATE_SAVE_RESULTS = 'evaluate.results.save';
// load results from local storage
export const EVALUATE_LOAD_RESULTS = 'evaluate.results.load';
// reset results in the app and in local storage
export const EVALUATE_RESET_RESULTS = 'evaluate.results.reset';

export const RESET_VIDEO = 'evaluate.video.reset';

export const actions = {
  setActiveExperiment: (experimentId: string): SetExperimentType => (
    { type: EVALUATE_SET_EXPERIMENT, experimentId }
  ),
  onPlaybackProgress: (progress: PlaybackProgressType): OnPlaybackProgressType => (
    { type: EVALUATE_ON_PLAYBACK_PROGRESS, progress }
  ),
  onPlaybackEnd: (): OnPlaybackEndType => (
    { type: EVALUATE_ON_PLAYBACK_END }
  ),
  play: (): PlayType => ({ type: EVALUATE_PLAY }),
  stop: (): StopType => ({ type: EVALUATE_STOP }),
  setActivity: (activityKey: string): SetActivityType => ({ type: EVALUATE_SET_ACTIVITY, activityKey }),
  saveResults: (state: EvaluateStateType): SaveResultsType => {
    const results = _.cloneDeep(state.results);
    const experimentId = state.currentExperiment!.id;
    const videoFilename = state.currentVideoUrl!;
    const activityKeys = Object.keys(state.activities);
    if (!results[experimentId]) {
      results[experimentId] = {};
    }
    if (!results[experimentId][videoFilename]) {
      results[experimentId][videoFilename] = {};
    }
    const result = results[experimentId][videoFilename];
    activityKeys.forEach((activityKey) => {
      result[activityKey] = state.activities[activityKey];
    });

    localStorage.setItem('results', JSON.stringify(results));

    return {
      type: EVALUATE_SAVE_RESULTS,
      results
    };
  },
  loadResults: (): LoadResultsType => {
    let results = {};
    try {
      const cachedResults = localStorage.getItem('results');
      if (cachedResults) {
        results = JSON.parse(cachedResults);
      }
    } catch (err) {
      console.error(err);
    }
    return {
      type: EVALUATE_LOAD_RESULTS,
      results
    };
  },
  resetResults: (): ResetResultsType => {
    localStorage.setItem('results', JSON.stringify({}));
    return {
      type: EVALUATE_RESET_RESULTS,
      results: {}
    };
  },
  resetVideo: () => ({
    type: RESET_VIDEO
  })
};

import React from 'react';
import { connect } from 'react-redux';
import { Button, HTMLTable } from '@blueprintjs/core';
import { EvaluatePageRouteProps } from '../../App';
import { Link } from '../components/Link';
import { Grid, Row, Col } from '../components/grid';
import { history, StoreType } from '../../store';
import { experiments, ExperimentType } from '../../config';
import style from './ResultsPage.module.scss';

type ResultsPageType = StoreType & EvaluatePageRouteProps;

@connect(store => store)
export class ResultsPage extends React.Component<ResultsPageType> {
  componentWillMount = () => {
    document.body.className = 'white';
  }

  getExperiment = () => {
    const experimentId = this.props.match.params.experimentId;
    const experiment = experiments.find(experiment => experiment.id === experimentId);
    // FIXME !
    return experiment!;
  }

  goBack = () => history.push({ pathname: '/' });

  render() {
    const experimentId = this.props.match.params.experimentId;
    const experimentConfig = experiments.find(experiment => experiment.id === experimentId);
    const results = this.props.evaluate.results[experimentId];
    if (!results || !experimentConfig) {
      return null;
    }
    const videos = (experimentConfig.videos as any);
    const videoFilenames: string[] = videos.map(video => video.filename);

    const tableHeader = Object.entries(experimentConfig!.activities)
      .map(([, activity]) => activity);

    const tableBody = videoFilenames.map((videoFilename) => {
      if (results[videoFilename]) {
        // FIXME Math.round might skew the results
        return Object.entries(results[videoFilename]).map(([, activity]) => ({
          key: activity.name,
          value: `${Math.round(activity.value)}`,
          count: activity.count
        }));
      } else {
        return Object.entries(experimentConfig!.activities)
          .map(([, activity]) => ({
            key: activity!.name,
            value: '-',
            count: 0
          }));
      }
    });

    return (
      <>
        {this.renderNavbar()}
        <Grid className={style.container} fluid={true}>
          {this.renderTimeTable(tableHeader, tableBody, videos)}
          {this.getExperiment().id === 'epm' ? this.renderEntriesTable(tableHeader, tableBody, videos) : ''}
        </Grid>
      </>
    );
  }

  renderTimeTable(tableHeader, tableBody, videos) {
    return (
      <Row>
        <Col md={12}>
          <h2 className={style.tableTextHeader}>Time</h2>
          <HTMLTable className={style.table}>
            <thead>
              <tr>
                <th className={style.tableVideoColumn}>Video</th>
                {tableHeader.map(activity => (
                  <th key={activity!.name}>{activity!.name} [s]</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableBody.map((row, i) => (
                <tr key={`result-${i}`}>
                  <td>{videos[i].name}</td>
                  {row.map(column => (
                    <td key={column.key} className={style.tableValue}>{column.value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </HTMLTable>
        </Col>
      </Row>
    );
  }

  // FIXME refactor and merge two table methods
  // FIXME refactory ugly function API
  renderEntriesTable(tableHeader, tableBody, videos) {
    return (
      <Row>
        <Col md={12}>
          <h2 className={style.tableTextHeader}>Entries</h2>
          <HTMLTable className={style.table}>
            <thead>
              <tr>
                <th className={style.tableVideoColumn}>Video</th>
                {tableHeader.map(activity => (
                  <th key={activity!.name}>{activity!.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableBody.map((row, i) => (
                <tr key={`result-${i}`}>
                  <td>{videos[i].name}</td>
                  {row.map(column => (
                    <td key={column.key} className={style.tableValue}>{column.count}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </HTMLTable>
        </Col>
      </Row>
    );
  }

  renderNavbar() {
    const experimentId = this.props.match.params.experimentId;

    return (
      <div className={style.navbar}>
        <Link to={`/experiment/${experimentId}`} color="white" className={style.navbarBackButton}>Back</Link>
        <h1 className={style.navbarHeader}>{this.getExperiment().name} - Results</h1>
      </div>
    );
  }
}

import React from 'react';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { ExperimentsPage } from './app/Experiments/ExperimentsPage';
import { ExperimentPage } from './app/Experiment/ExperimentPage';
import { EvaluatePage } from './app/Evaluate/EvaluatePage';
import { ResultsPage } from './app/Results';
import { actions } from './app/Evaluate/actions';
import { history, StoreType } from './store';

history.listen((location) => {
  ReactGA.pageview(location.pathname + location.search + location.hash);
});

type RoutesPageProps = typeof actions & StoreType;

@connect(store => store, actions)
export class Routes extends React.Component {
  componentDidMount() {
    (this.props as RoutesPageProps).loadResults();
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        <>
          <Route exact path="/" component={ExperimentsPage} />
          <Route path="/experiment/:experimentId" component={ExperimentPage} />
          <Route path="/evaluate/:experimentId" component={EvaluatePage} />
          <Route path="/results/:experimentId" component={ResultsPage} />
        </>
      </ConnectedRouter>
    );
  }
}

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import 'typeface-lato';
import { Provider } from 'react-redux';
import { FocusStyleManager } from '@blueprintjs/core';
import { store } from './store';
import { Routes } from './Routes';

import './App.scss';

FocusStyleManager.onlyShowFocusOnTabs();

export type EvaluatePageRouteProps = RouteComponentProps<{
  experimentId: string;
}>;

const App = () => (
  <Provider store={store}>
    <Routes />
  </Provider>
);

export default App;

import React from 'react';
import classNames from 'classnames';
import styles from './Grid.module.scss';

export interface GridProps {
  fluid?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const Grid = (props: GridProps) => {
  const className = classNames([styles[props.fluid ? 'container-fluid' : 'container'], props.className]);

  return <div className={className}>{props.children}</div>;
};

export const experiments = [{
  id: 'fst',
  name: 'Forced Swim Test',
  activities: {
    immobility: {
      name: 'Immobility',
      default: true
    },
    swimming: {
      name: 'Swimming'
    },
    climbing: {
      name: 'Climbing'
    }
  },
  videos: [
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397441282' : './video/fst-kontrola/kort-02_.mp4',   name: 'Control 1' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397441280' : './video/fst-kontrola/nlx204-01_.mp4', name: 'Control 2' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397441225' : './video/fst-kontrola/nlx204-02_.mp4', name: 'Control 3' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397441218' : './video/fst-kontrola/nlx204-03_.mp4', name: 'Control 4' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397441171' : './video/fst-kontrola/nlx204-04_.mp4', name: 'Control 5' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397441170' : './video/fst-kontrola/nlx204-05_.mp4', name: 'Control 6' },

    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397440934' : './video/fst-badany/befiradol-01_.mp4', name: 'Compound 1' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397440932' : './video/fst-badany/befiradol-02_.mp4', name: 'Compound 2' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397440871' : './video/fst-badany/f15-01_.mp4',       name: 'Compound 3' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397440869' : './video/fst-badany/f15-02_.mp4',       name: 'Compound 4' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397440795' : './video/fst-badany/hbk15-01_.mp4',     name: 'Compound 5' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397440792' : './video/fst-badany/hbk15-02_.mp4',     name: 'Compound 6' }
  ]
}, {
  id: 'epm',
  name: 'Elevated Plus Maze',
  activities: {
    closed: {
      name: 'Closed',
      default: true
    },
    center: {
      name: 'Center'
    },
    open: {
      name: 'Open'
    }
  },
  videos: [
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397429126' : './video/epm-kontrola/KONT 3 EPM.mp4',   name: 'Control 1' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397429124' : './video/epm-kontrola/KONT 4 EPM.mp4',   name: 'Control 2' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397429095' : './video/epm-kontrola/KONT 5 EPM.mp4',   name: 'Control 3' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397429094' : './video/epm-kontrola/kont 1 EPM.mp4',   name: 'Control 4' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397428716' : './video/epm-badany/D 0.3125 1 EPM.mp4', name: 'Compound 1' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397428711' : './video/epm-badany/D 1.25 1 EPM.mp4',   name: 'Compound 2' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397428683' : './video/epm-badany/D 2.5 3 EPM.mp4',    name: 'Compound 3' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397428682' : './video/epm-badany/D 2.5 4 EPM.mp4',    name: 'Compound 4' }
  ]
}, {
  id: 'nor',
  name: 'Novel Object Recognition',
  activities: {
    notExploring: {
      name: 'Not Exploring',
      default: true
    },
    oldObject: {
      name: 'Old Object'
    },
    newObject: {
      name: 'Novel Object'
    }
  },
  videos: [
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397453804' : './video/nor-control/HBK 15 0,3 myszy 1, 2 - p2.mp4',  name: 'Control 1' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397453796' : './video/nor-control/HBK 15 0,3 myszy 3, 4 - p2.mp4',  name: 'Control 2' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397453768' : './video/nor-control/HBK 15 5 myszy 5, 6 - p2.mp4',    name: 'Control 3' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397453759' : './video/nor-control/HBK-15 2,5 mysz 3,4 - p2.mp4',    name: 'Control 4' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397453738' : './video/nor-control/HBK-15 2,5 mysz 5,6 - p2.mp4',    name: 'Control 5' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397453730' : './video/nor-control/SCOPO 1 myszy 5, 6 - p2.mp4',     name: 'Control 6' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397453702' : './video/nor-control/SCOPO 1 myszy 7, 8 - p2.mp4',     name: 'Control 7' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397453697' : './video/nor-control/SCOPO 1 myszy 9, 10 - p1.mp4',    name: 'Control 8' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397453649' : './video/nor-control/kontrola 5, 6 - p1.mp4',          name: 'Control 9' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397453647' : './video/nor-control/kontrola 5, 6 - p2.mp4',          name: 'Control 10' },

    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397446716' : './video/nor-compound/HBK 15 0,3 myszy 3, 4 - p1.mp4', name: 'Compound 1' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397446705' : './video/nor-compound/HBK-15 1,25 mysz 3,4 - p1.mp4',  name: 'Compound 2' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397446693' : './video/nor-compound/HBK-15 2,5 mysz 1,2 - p2.mp4',   name: 'Compound 3' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397446681' : './video/nor-compound/HBK-15 5 mysz 3,4 - p2.mp4',     name: 'Compound 4' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397446663' : './video/nor-compound/SCOPO 1 myszy 3, 4 - p1.mp4',    name: 'Compound 5' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397446645' : './video/nor-compound/SCOPO 1 myszy 3, 4 - p2.mp4',    name: 'Compound 6' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397446628' : './video/nor-compound/hbk-15 1,25 mysz 1, 2 - p1.mp4', name: 'Compound 7' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397446607' : './video/nor-compound/kontrola 3,4 - p1.mp4',          name: 'Compound 8' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397446556' : './video/nor-compound/kontrola 3,4 - p2.mp4',          name: 'Compound 9' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397446555' : './video/nor-compound/kontrola* 1,2 - p1.mp4',         name: 'Compound 10' }
  ]
}, {
  id: 'pain',
  name: 'Formalin Test',
  activities: {
    noReaction: {
      name: 'No Reaction',
      default: true
    },
    reaction: {
      name: 'Nociceptive Reaction'
    }
  },
  videos: [
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397456312' : './video/pain-control/CaptureSync 10-4-2019 18-10-49-part-1.mp4',  name: 'Control - Early phase' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397456311' : './video/pain-control/CaptureSync 10-4-2019 18-10-49-part-2.mp4',  name: 'Control - Late phase' },

    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397455839' : './video/pain-compound/CaptureSync 10-4-2019 18-51-56-part-1.mp4', name: 'Compound - Early phase' },
    { filename: process.env.REACT_APP_TARGET === 'browser' ? 'https://vimeo.com/397455840' : './video/pain-compound/CaptureSync 10-4-2019 18-51-56-part-2.mp4', name: 'Compound - Late phase' }
  ]
}];

export type ExperimentType = typeof experiments[0];
import React from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import style from './Link.module.scss';

type LinkProps = {
  to: string;
  children?: React.ReactNode;
  className?: string;
  color?: 'blue' | 'white';
};

export const Link = (props: LinkProps) => {
  const classes = [props.className, style.link];
  if (props.color === 'white') {
    classes.push(style.white);
  }

  return (
    <RouterLink
      className={classNames(classes)}
      to={props.to}
    >
      {props.children}
    </RouterLink>
  );
};


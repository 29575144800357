import React from 'react';
import classNames from 'classnames';
import styles from './Grid.module.scss';

export interface ColProps {
  className?: string;
  children: React.ReactNode;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xsOffset?: number;
  smOffset?: number;
  mdOffset?: number;
  lgOffset?: number;
  first?: string;
  last?: string;
}

const classMap = {
  xs: 'col-xs',
  sm: 'col-sm',
  md: 'col-md',
  lg: 'col-lg',
  xsOffset: 'col-xs-offset',
  smOffset: 'col-sm-offset',
  mdOffset: 'col-md-offset',
  lgOffset: 'col-lg-offset'
};

export const Col = (props: ColProps) => {
  let classes = [props.className, props.first && `first-${props.first}`, props.last && `last-${props.last}`];

  let keys = Object.keys(props);
  let filtered = keys.filter(key => classMap[key]);

  filtered.forEach((key) => {
    classes.push(styles[`${classMap[key]}-${props[key]}`]);
  });

  return <div className={classNames(classes)}>{props.children}</div>;
};

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { createHashHistory } from 'history';
import { evaluateReducer } from './app/Evaluate/reducer';
import { EvaluateStateType } from './app/Evaluate/reducer.types';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createHashHistory();

export const store = createStore(
  combineReducers({
    evaluate: evaluateReducer,
    router: connectRouter(history)
  }),
  composeEnhancers(
    applyMiddleware(thunk, routerMiddleware(history)),
  )
);

export type StoreType = {
  evaluate: EvaluateStateType;
  router: RouterState;
};
